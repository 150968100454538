import React, { Component } from "react"

class Error500 extends Component {
    render() {
        return (
            <div className="text-center">
                <h1>Error 500 Something Went Wrong</h1>
                <h2>Une erreur s'est produite merci de réessayer plus tard</h2>
                <h3>Retourner sur <a href="/">www.barnes-reporting.fr</a></h3>
            </div>
        )
    }
}

export default Error500