import React, { Component } from "react"
import Axios from "axios"

import Message from "../layout/Message"
import Spinner from "../layout/Spinner"

class LoginForm extends Component {
    axiosCancelToken = Axios.CancelToken.source() // used to prevent state update on unmounted component after async request

    constructor(props) {
        super(props)
        this.state = {
            email : '',
            password: '',
            error: [],
            loading: false
        }
    }

    handleInputChange = event => {
        const {value, name} = event.target
        this.setState({
            [name]: value
        })
    }

    onSubmit = event => {
        event.preventDefault()
        this.setState({loading: true})
        Axios.post(process.env.REACT_APP_API_HOST + '/api/users/login', {
            email: this.state.email,
            password: this.state.password,
        }, {withCredentials: true, cancelToken: this.axiosCancelToken.token})
            .then(res => {
                this.setState({loading: false})
                if (res.data.error) {
                    this.setState({error: <Message message={res.data.error_msg} type="warning" hidden={false} />})
                } else {
                    this.setState({error: <Message message="Connexion réussi, redirection..." type="success" dismiss="false" hidden={false} />})
                    sessionStorage.setItem('permissions', res.data.permissions)
                    this.props.update()
                    this.props.history.push('/reporting')
                }
            })
            .catch(err => this.props.axiosHandler(err, this))
    }

    componentWillUnmount() {
        this.axiosCancelToken.cancel()
    }

    render() {
        return (
            <div className="container col-xl-5 col-lg-6 col-md-8 col-sm-10 col-xs-12">
                <div className="card">
                    {this.state.loading ? <Spinner /> : null}
                    <div className="card-body">
                        <h5 className="card-title text-center">Se Connecter</h5>
                        {this.state.error}
                        <form id="loginForm" onSubmit={this.onSubmit}>
                            <div className="form-group">
                                <label htmlFor="email">Adresse Email</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    id="email"
                                    name="email"
                                    placeholder="Adresse Email"
                                    value={this.state.email}
                                    onChange={this.handleInputChange}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="password">Mot de Passe</label>
                                <input
                                    type="password"
                                    className="form-control"
                                    id="password"
                                    name="password"
                                    placeholder="Mot de Passe"
                                    value={this.state.password}
                                    onChange={this.handleInputChange}/>
                            </div>
                            <button type="submit" className="btn btn-block btn-primary">Se Connecter</button>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

export default LoginForm