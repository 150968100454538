import React, { Component } from "react"
import Axios from 'axios'

import Spinner from '../layout/Spinner'
import Message from '../layout/Message'

import './Reporting.css'

import $ from 'jquery'
import 'datatables.net-bs4'
import 'datatables.net-buttons-bs4'
import 'datatables.net-buttons/js/buttons.html5.min.js'
import 'datatables.net-keytable-bs4'
import 'datatables.net-keytable-bs4/css/keyTable.bootstrap4.min.css'
import 'datatables.net-fixedcolumns-bs4'
import 'datatables.net-fixedcolumns-bs4/css/fixedColumns.bootstrap4.min.css'
import Select from 'react-select'
import JSZip from 'jszip'
window.JSZip = JSZip

function createReportDataTable(DOMTable, columns, cellFocusCB) {
    for (var i = 0 ; i < columns.length ; i++) {
        if (columns[i] && columns[i].render === "number")
            columns[i].render = (el, type) => {
                if (type === "display")
                    return el.replace(/\B(?=(\d{3})+(?!\d))/g, " ").replace('.', ',')
                if (type === "exportCopy")
                    return el.replace('.', ',')
                return el
            }
    }
    var datatable = DOMTable.DataTable({
        columns,
        scrollX: true,
        searching: false,
        keys: true,
        fixedColumns: true,
        scrollCollapse: true,
        ordering: false,
        "lengthMenu": [-1],
        dom: "<'row d-flex justify-content-end mx-auto'B>" +
        "<'row text-nowrap'<'col-sm-12'tr>>",
        buttons: [
            {
                extend: 'excel',
                text: 'Excel',
                filename: ('Reporting ' + new Date().toLocaleDateString('fr-FR')),
                title: '',
                exportOptions: {orthogonal: 'exportExcel'}
            },
            {
                extend: 'copy',
                text: 'Copier',
                title: '',
                exportOptions: {orthogonal: 'exportCopy'}
            }
        ],
        language: {
            processing:     "Traitement en cours...",
            lengthMenu:     "Afficher _MENU_ &eacute;l&eacute;ments",
            info:           "Affichage de l'&eacute;lement _START_ &agrave; _END_ sur _TOTAL_ &eacute;l&eacute;ments",
            infoEmpty:      "Affichage de l'&eacute;lement 0 &agrave; 0 sur 0 &eacute;l&eacute;ments",
            infoFiltered:   "(filtr&eacute; de _MAX_ &eacute;l&eacute;ments au total)",
            infoPostFix:    "",
            loadingRecords: "Chargement en cours...",
            zeroRecords:    "Aucun &eacute;l&eacute;ment ne correspondent",
            emptyTable:     "Aucune donnée disponible dans le tableau",
            paginate: {
                first:      "Premier",
                previous:   "Pr&eacute;c&eacute;dent",
                next:       "Suivant",
                last:       "Dernier"
            },
            aria: {
                sortAscending:  ": activer pour trier la colonne par ordre croissant",
                sortDescending: ": activer pour trier la colonne par ordre décroissant"
            }
        }
    })

    var clicked = {}

    datatable
        .on('key-focus', () => {
            clicked = {row: datatable.cell({focused: true}).index().row, column: datatable.cell({focused: true}).index().column}
        })
        .on('key-refocus', () => {
            if (clicked.row === datatable.cell({focused: true}).index().row && clicked.column === datatable.cell({focused: true}).index().column) {
                var row = datatable.cell({focused: true}).index().row
                var column = datatable.cell({focused: true}).index().column
                cellFocusCB(datatable.cell(row, 0).data(), $(datatable.column(column).header()).html())
                datatable.cell.blur()
            }
        })

    return datatable
}

function createContractDataTable(DOMTable, columns) {
    for (var i = 0 ; i < columns.length ; i++) {
        if (columns[i] && columns[i].render === "number")
            columns[i].render = (el, type) => {
                if (type === "display")
                    return el.replace(/\B(?=(\d{3})+(?!\d))/g, " ").replace('.', ',')
                if (type === "exportCopy")
                    return el.replace('.', ',')
                return el
            }
        else if (columns[i] && columns[i].render === "date")
            columns[i].render = (el, type) => {
                if (!el)
                    return ""
                if(type === "display" || type === "exportCopy") {
                    var date = new Date(el).toISOString().split('T')[0]
                    return date.split('-')[2] + '/' + date.split('-')[1] + '/' + date.split('-')[0]
                }
                return new Date(el)
            }
        else
            columns[i] = null
    }
    return DOMTable.DataTable({
        columns,
        scrollX: true,
        searching: false,
        keys: true,
        scrollCollapse: true,
        "lengthMenu": [[10, 20, 50, 100, -1], [10, 20, 50, 100, "Tout"]],
        dom: "<'row mx-0'<'mr-auto'l><B>>" +
        "<'row text-nowrap'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-5'i><'col-sm-7'p>>",
        buttons: [
            {
                extend: 'excel',
                text: 'Excel',
                filename: ('Reporting ' + new Date().toLocaleDateString('fr-FR')),
                title: '',
                exportOptions: {orthogonal: 'exportExcel'}
            },
            {
                extend: 'copy',
                text: 'Copier',
                title: '',
                exportOptions: {orthogonal: 'exportCopy'}
            }
        ],
        language: {
            processing:     "Traitement en cours...",
            lengthMenu:     "Afficher _MENU_ &eacute;l&eacute;ments",
            info:           "Affichage de l'&eacute;lement _START_ &agrave; _END_ sur _TOTAL_ &eacute;l&eacute;ments",
            infoEmpty:      "Affichage de l'&eacute;lement 0 &agrave; 0 sur 0 &eacute;l&eacute;ments",
            infoFiltered:   "(filtr&eacute; de _MAX_ &eacute;l&eacute;ments au total)",
            infoPostFix:    "",
            loadingRecords: "Chargement en cours...",
            zeroRecords:    "Aucun &eacute;l&eacute;ment ne correspondent",
            emptyTable:     "Aucune donnée disponible dans le tableau",
            paginate: {
                first:      "Premier",
                previous:   "Pr&eacute;c&eacute;dent",
                next:       "Suivant",
                last:       "Dernier"
            },
            aria: {
                sortAscending:  ": activer pour trier la colonne par ordre croissant",
                sortDescending: ": activer pour trier la colonne par ordre décroissant"
            }
        }
    })
}

class App extends Component {
    axiosCancelToken = Axios.CancelToken.source() // used to prevent state update on unmounted component after async request

    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            loading2: false,
            error: null,
            agencies: null,
            agencyReportTable: null,
            agencyContractTable: null,
            agency: null,
            users: null,
            displayedUsers: null,
            user: "",
            userReportTable: null,
            userContractTable: null,
            propertyCategories: null,
            selectedPropertyCategories: null,
            modalName: "Contrats",
            year: {year: new Date().getFullYear()},
            years: [
                {year: 2024}, 
                {year: 2023}, 
                {year: 2022}, 
                {year: 2021},
                {year: 2020},
                {year: 2019},
                {year: 2018},
                {year: 2017},
                {year: 2016},
                {year: 2015}
            ]
        }
    }

    handleInputChange = event => {
        const {value, name} = event.target
        this.setState({
            [name]: value
        })
    }

    updateAgencyContractsTable = (row, column) => {
        Axios.get(process.env.REACT_APP_API_HOST + '/api/reporting/agency/' + this.state.agency.id + '/contracts', {
            withCredentials: true,
            cancelToken: this.axiosCancelToken.token,
            params: {
                propertyCategories: this.state.selectedPropertyCategories ? this.state.selectedPropertyCategories.map(el => el.id) : [],
                year: this.state.year.year,
                row,
                column
            }
        })
            .then(res => {
                if (res.status === 200)
                    return this.setState({agencyContractTable: res.data.result, modalName: res.data.name}, () => {
                        this.agencyContractDataTable = createContractDataTable($(this.agencyContractTable), res.data.columns)
                        $("#modalContractTable").one("shown.bs.modal", () => {
                            this.agencyContractDataTable.columns.adjust().draw()
                        })
                        $("#modalContractTable").one("hide.bs.modal", () => {
                            this.agencyContractDataTable.destroy()
                            this.setState({agencyContractDataTable: null, agencyContractTable: null})
                        })
                        $("#modalContractTable").modal('show')
                    })
                else if (res.status === 204)
                    return
            })
            .catch(err => this.props.axiosHandler(err, this))
    }

    updateUserContractsTable = (row, column) => {
        Axios.get(process.env.REACT_APP_API_HOST + '/api/reporting/user/' + this.state.user.id + '/contracts', {
            withCredentials: true,
            cancelToken: this.axiosCancelToken.token,
            params: {
                propertyCategories: this.state.selectedPropertyCategories ? this.state.selectedPropertyCategories.map(el => el.id) : [],
                year: this.state.year.year,
                row,
                column
            }
        })
            .then(res => {
                if (res.status === 200)
                    return this.setState({userContractTable: res.data.result, modalName: res.data.name}, () => {
                        this.userContractDataTable = createContractDataTable($(this.userContractTable), res.data.columns)
                        $("#modalContractTable").one("shown.bs.modal", () => {
                            this.userContractDataTable.columns.adjust().draw()
                        })
                        $("#modalContractTable").one("hide.bs.modal", () => {
                            this.userContractDataTable.destroy()
                            this.setState({userContractDataTable: null, userContractTable: null})
                        })
                        $("#modalContractTable").modal('show')
                    })
                else if (res.status === 204)
                    return
            })
            .catch(err => this.props.axiosHandler(err, this))
    }

    updateAgencyTable() {
        if (this.agencyReportDataTable) {
            this.agencyReportDataTable.destroy()
            this.agencyReportDataTable = null
        }

        if (this.state.agency)
            this.setState({
                agencyReportTable: null,
                loading: true,
                user: this.state.user.agency !== this.state.agency.id ? "" : this.state.user
            }, () => {
                Axios.get(process.env.REACT_APP_API_HOST + '/api/reporting/agency/' + this.state.agency.id, {
                        withCredentials: true,
                        cancelToken: this.axiosCancelToken.token,
                        params: {
                            propertyCategories: this.state.selectedPropertyCategories ? this.state.selectedPropertyCategories.map(el => el.id) : [],
                            year: this.state.year.year
                        }
                    })
                    .then(res => this.setState({agencyReportTable: res.data.table, loading: false}, () => {
                        if (res.data.error)
                            return this.setState({error: <Message message={res.data.error_msg} type="warning" hidden={false} />})
                        this.agencyReportDataTable = createReportDataTable($(this.agencyReportTable), res.data.columns, this.updateAgencyContractsTable)
                    }))
                    .catch(err => this.props.axiosHandler(err, this))
            })
        else
            this.setState({agencyReportTable: null})
    }

    updateUserTable() {
        if (this.userReportDataTable) {
            this.userReportDataTable.destroy()
            this.userReportDataTable = null
        }
        if (this.state.user)
            this.setState({
                userReportTable: null,
                loading2: true
            }, () => {
                Axios.get(process.env.REACT_APP_API_HOST + '/api/reporting/user/' + this.state.user.id, {
                    withCredentials: true,
                    cancelToken: this.axiosCancelToken.token,
                    params: {
                        propertyCategories: this.state.selectedPropertyCategories ? this.state.selectedPropertyCategories.map(el => el.id) : [],
                        year: this.state.year.year
                    }
                })
                    .then(res => this.setState({userReportTable: res.data.table, loading2: false}, () => {
                        if (res.data.error)
                            return this.setState({error: <Message message={res.data.error_msg} type="warning" hidden={false} />})
                        this.userReportDataTable = createReportDataTable($(this.userReportTable), res.data.columns, this.updateUserContractsTable)
                    }))
                    .catch(err => this.props.axiosHandler(err, this))
            })
        else
            this.setState({userReportTable: null})
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.agency !== this.state.agency) {
            this.updateAgencyTable()
        }
        if (prevState.user !== this.state.user) {
            this.updateUserTable()
        }
    }

    componentDidMount() {
        this._isMounted = true

        Axios.get(process.env.REACT_APP_API_HOST + '/api/users/self/authorized_reports', {withCredentials: true, cancelToken: this.axiosCancelToken.token})
            .then(res => this.setState({
                agencies: res.data.authorized_reports.filter(el => el.type === "agency").length > 0 ? res.data.authorized_reports.filter(el => el.type === "agency") : null,
                agency: res.data.authorized_reports.filter(el => el.type === "agency").length > 0 ? res.data.authorized_reports.filter(el => el.type === "agency")[0] : null,
                users: res.data.authorized_reports.filter(el => el.type === "user").length > 0 ? res.data.authorized_reports.filter(el => el.type === "user") : null,
                loading: false
            }))
            .catch(err => this.props.axiosHandler(err, this))

        Axios.get(process.env.REACT_APP_API_HOST + '/api/reporting/catalogs/property_type', {withCredentials: true, cancelToken: this.axiosCancelToken.token})
            .then(res => this.setState({
                propertyCategories: res.data.categories
            }))
            .catch(err => this.props.axiosHandler(err, this))
    }

    submitFilters = () => {
        this.updateAgencyTable()
        this.updateUserTable()
    }

    handleMultiSelectChange = (newValue, actionMeta) => {
        this.setState({[actionMeta.name]: newValue})
    }

    componentWillUnmount() {
        this.axiosCancelToken.cancel()
    }

    render() {
        return(
            <div className="container col-10">
                <div className="card">
                    {this.state.loading || this.state.loading2 ? <Spinner /> : null}
                    <div className="card-body">
                        <h5 className="card-title text-center">Reporting</h5>
                        {this.state.error}
                        <div className="col-12 px-0 my-3">
                            <div className="row my-2">
                                <div className="col-md-6 col-sm">
                                    <h6>Rapports: </h6>
                                    <label htmlFor="contractCategory">Année:</label>
                                    <Select
                                        id="year"
                                        name="year"
                                        value={this.state.year}
                                        options={this.state.years}
                                        getOptionValue={option => option['year']}
                                        getOptionLabel={option => option['year']}
                                        styles={{ menu: styles => ({ ...styles, zIndex: 999 }) }}
                                        onChange={this.handleMultiSelectChange} />
                                </div>
                                <div className="col-md-6 col-sm">
                                    <h6>Contrats:</h6>
                                    <label htmlFor="contractCategory">Catégorie(s):</label>
                                    <Select
                                        id="contractCategory"
                                        name="selectedPropertyCategories"
                                        isMulti
                                        placeholder="Séléctionner..."
                                        value={this.state.selectedPropertyCategories}
                                        options={this.state.propertyCategories}
                                        closeMenuOnSelect={false}
                                        getOptionValue={option => option['id']}
                                        getOptionLabel={option => option['name']}
                                        styles={{ menu: styles => ({ ...styles, zIndex: 999 }) }}
                                        onChange={this.handleMultiSelectChange} />
                                </div>
                            </div>
                            <button className="btn btn-block btn-primary col-3" onClick={this.submitFilters}>Actualiser</button>
                        </div>
                        <div className="modal fade" id="modalContractTable" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLongTitle">{this.state.modalName}</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        {this.state.agencyContractTable ? (<table className="table bg-light table-striped table-bordered table-hover w-100" id="agencyContractTable" ref={el => this.agencyContractTable = el} dangerouslySetInnerHTML={{__html: this.state.agencyContractTable}} />) : null}
                                        {this.state.userContractTable ? (<table className="table bg-light table-striped table-bordered table-hover w-100" id="userContractTable" ref={el => this.userContractTable = el} dangerouslySetInnerHTML={{__html: this.state.userContractTable}} />) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.state.agencies ? (
                            <div className="col-12 px-0">
                                <Select
                                    className="col-3 px-0"
                                    name="agency"
                                    value={this.state.agency}
                                    onChange={this.handleMultiSelectChange}
                                    getOptionValue={option => option['id']}
                                    getOptionLabel={option => option['name']}
                                    options={this.state.agencies}
                                    styles={{ menu: styles => ({ ...styles, zIndex: 999 }) }} />
                                {this.state.agencyReportTable ? (<table className="table bg-light table-striped table-bordered table-hover w-100" id="agencyReportTable" ref={el => this.agencyReportTable = el} dangerouslySetInnerHTML={{__html: this.state.agencyReportTable}} />) : null}
                            </div>
                        ) : null }
                        {(this.state.agency && this.state.users && this.state.users.filter(el => el.agency === this.state.agency.id).length > 0) || (this.state.users && this.state.users.length > 0 && !this.state.agency) ? (
                            <div className="col-12 mt-3 px-0">
                                <Select
                                    className="col-3 px-0"
                                    name="user"
                                    placeholder="Séléctionner..."
                                    value={this.state.user}
                                    onChange={this.handleMultiSelectChange}
                                    getOptionValue={option => option['id']}
                                    getOptionLabel={option => option['name']}
                                    options={this.state.users.filter(el => this.state.agency ? el.agency === this.state.agency.id : true)}
                                    styles={{ menu: styles => ({ ...styles, zIndex: 999 }) }} />
                                {this.state.userReportTable ? (<table className="table bg-light table-striped table-bordered table-hover w-100" id="userReportTable" ref={el => this.userReportTable = el} dangerouslySetInnerHTML={{__html: this.state.userReportTable}} />) : null}
                            </div>
                        ) : null }
                    </div>
                </div>
            </div>
        )
    }
}

export default App
