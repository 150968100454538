import React, { Component } from "react"
import { NavLink as Link } from 'react-router-dom'

import "./Navbar.css"

function NavLink (props) {
    return (
        <Link to={props.link} className="nav-link nav-item" activeClassName='active' {...props.childProps}>{props.text}</Link>
    )
}

function NavButton (props) {
    return (
        <button className="nav-link nav-item navbutton" onClick={props.onClick}>{props.text}</button>
    )
}

class NavBar extends Component {
  render() {
    var linkPart1 = [], linkPart2 = []
    for (var i in this.props.children) {
        if (i%2 === 0) {
            linkPart1.push(this.props.children[i])
        } else {
            linkPart2.push(this.props.children[i])
        }
    }
    return (
        <nav className="navbar navbar-expand-lg navbar-light justify-content-center fixed-top" id="mainNavBar">
            <div className="container-fluid">
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target=".dual-nav">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="navbar-collapse collapse dual-nav order-1 order-lg-0 w-50">
                    <div className="navbar-nav ml-auto">
                        {linkPart1}
                    </div>
                </div>
                <Link to="/" className="navbar-brand order-0 order-lg-1">Barnes Reporting</Link>
                <div className="navbar-collapse collapse dual-nav order-2 order-lg-2 w-50">
                    <div className="navbar-nav mr-auto">
                        {linkPart2}
                    </div>
                </div>
            </div>
        </nav>
    )
  }
}

export default NavBar
export { NavLink, NavButton }