import React, { Component } from "react"
import ReactDOM from 'react-dom'
import { Route, Switch, NavLink as Link } from 'react-router-dom'

import Spinner from "../layout/Spinner"
import $ from 'jquery'
import datatables from 'datatables.net-bs4'
import Axios from 'axios'
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css'
import { PrivateRoute } from '../PrivateRoute'
import Message from '../layout/Message'
import Error404 from '../errors/404'
import Select from 'react-select'

$.DataTable = datatables

class AdminIndex extends Component {
    axiosCancelToken = Axios.CancelToken.source() // used to prevent state update on unmounted component after async request

    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            error: null
        }
    }

    componentDidMount() {
        Axios.get(process.env.REACT_APP_API_HOST + '/api/users', {withCredentials: true, cancelToken: this.axiosCancelToken.token})
            .then(res => {
                this.setState({loading: false})
                this.usersDataTable = $(this.usersTable).DataTable({
                    data: res.data,
                    scrollX: true,
                    scrollY: '60vh',
                    scrollCollapse: true,
                    columns: [
                        {'data': 'name',
                        'title': 'Nom',
                        'defaultContent': ''},
                        {'data': 'email',
                        'title': 'Email',
                        'defaultContent': ''},
                        {'data': 'permissions',
                        'title': 'Permissions',
                        'defaultContent': ''},
                        {'data': 'created.date',
                        'title': 'Créé le',
                        'render': (date) => new Date(date).toLocaleString('fr-FR')},
                        {'data': 'connected.date',
                        'title': 'Dernière connexion le',
                        'render': (date) => {if(date){return new Date(date).toLocaleString('fr-FR')}else{return ""}}},
                        {'data': null,
                        'title': 'Editer',
                        createdCell: (td, cellData, rowData, row, col) => 
                            ReactDOM.render(<button type="button" className="btn btn-success" onClick={() => this.props.history.push('admin/edit/' + rowData._id)}>Editer</button>, td)
                        }
                    ],
                    language: {
                        processing:     "Traitement en cours...",
                        lengthMenu:     "Afficher _MENU_ &eacute;l&eacute;ments",
                        info:           "Affichage de l'&eacute;lement _START_ &agrave; _END_ sur _TOTAL_ &eacute;l&eacute;ments",
                        infoEmpty:      "Affichage de l'&eacute;lement 0 &agrave; 0 sur 0 &eacute;l&eacute;ments",
                        infoFiltered:   "(filtr&eacute; de _MAX_ &eacute;l&eacute;ments au total)",
                        infoPostFix:    "",
                        loadingRecords: "Chargement en cours...",
                        zeroRecords:    "Aucun &eacute;l&eacute;ment ne correspondent",
                        emptyTable:     "Aucune donnée disponible dans le tableau",
                        paginate: {
                            first:      "Premier",
                            previous:   "Pr&eacute;c&eacute;dent",
                            next:       "Suivant",
                            last:       "Dernier"
                        },
                        aria: {
                            sortAscending:  ": activer pour trier la colonne par ordre croissant",
                            sortDescending: ": activer pour trier la colonne par ordre décroissant"
                        }
                    }
                })
            })
            .catch(err => this.props.axiosHandler(err, this))
    }

    componentWillUnmount() {
        if(this.usersDataTable){
            this.usersDataTable.destroy()
        }

        this.axiosCancelToken.cancel()
    }

    render() {
        return (
            <div className="container col-sm-12 col-md-10 col-lg-9 col-xl-8">
                <div className="card">
                    {this.state.loading ? <Spinner /> : null}
                    <div className="card-body">
                        <h5 className="card-title text-center">Utilisateurs</h5>
                        {this.state.error}
                        <table className="table bg-light table-striped table-bordered table-hove w-100" id="usersTable" ref={el => this.usersTable = el}>
                        </table>
                        <Link className="btn col-4 offset-4 btn-primary" to="/admin/new">Nouveau</Link>
                    </div>
                </div>
            </div>
        )
    }
}

class AdminEdit extends Component {
    axiosCancelToken = Axios.CancelToken.source() // used to prevent state update on unmounted component after async request

    constructor(props) {
        super(props)
        this.state = {
            email: '',
            name: '',
            permissions: '',
            password: '',
            password2: '',
            loading: false,
            error: null,
            possible_reports: [],
            authorized_reports: []
        }
    }

    componentDidMount() {
        Axios.get(process.env.REACT_APP_API_HOST + '/api/users/' + this.props.match.params.id, {withCredentials: true, cancelToken: this.axiosCancelToken.token})
            .then(res => {
                if(res.data.error) {
                    return this.setState({error: <Message message={res.data.error_msg} type="warning" hidden={false} />})
                }
                this.setState({
                    email: res.data.email,
                    name: res.data.name,
                    permissions: res.data.permissions,
                    id: res.data._id,
                    authorized_reports: res.data.authorized_reports
                })
            })
            .catch(err => this.props.axiosHandler(err, this))

        Axios.get(process.env.REACT_APP_API_HOST + '/api/reporting/catalogs/possible_reports', {withCredentials: true, cancelToken: this.axiosCancelToken.token})
            .then(res => this.setState({possible_reports: res.data.possible_reports}))
            .catch(err => this.props.axiosHandler(err, this))
    }

    componentWillUnmount() {
        this.axiosCancelToken.cancel()
    }

    handleInputChange = event => {
        const {value, name} = event.target
        this.setState({
            [name]: value
        })
    }

    handleMultiSelectChange = (newValue, actionMeta) => {
        this.setState({[actionMeta.name]: newValue})
    }

    onSubmit = event => {
        event.preventDefault()
        this.setState({loading: true})
        Axios.patch(process.env.REACT_APP_API_HOST + '/api/users/' + this.props.match.params.id, {
            name: this.state.name,
            email: this.state.email,
            password: this.state.password,
            password2: this.state.password2,
            permissions: this.state.permissions,
            authorized_reports: this.state.authorized_reports
        }, {withCredentials: true, cancelToken: this.axiosCancelToken.token})
            .then(res => {
                this.setState({loading: false})
                if (res.data.error) {
                    this.setState({error: <Message message={res.data.error_msg} type="warning" hidden={false} />})
                } else {
                    this.setState({error: <Message message="Modifications réussies" type="success" hidden={false} />})
                    this.props.history.push('/admin')
                }
            })
            .catch(err => this.props.axiosHandler(err, this))
    }

    deleteUser = () => (
        Axios.delete(process.env.REACT_APP_API_HOST + '/api/users/' + this.props.match.params.id, {withCredentials: true, cancelToken: this.axiosCancelToken.token})
            .then(res => {
                this.setState({loading: false})
                if (res.data.error) {
                    this.setState({error: <Message message={res.data.error_msg} type="warning" hidden={false} />})
                } else {
                    this.setState({error: <Message message="Utilisateur supprimé" type="success" hidden={false} />})
                    this.props.history.push('/admin')
                }
            })
            .catch(err => this.props.axiosHandler(err, this))
    )

    render() {
        return(
            <div className="container col-xl-5 col-lg-6 col-md-8 col-sm-10 col-xs-12">
                <div className="card">
                    {this.state.loading ? <Spinner /> : null}
                    <div className="card-body">
                        <div className="row d-flex align-items-center mb-3">
                            <div className="w-25 pl-2">
                                <button className="btn" onClick={this.props.history.goBack}><span className="fa fa-arrow-left" /></button>
                            </div>
                            <div className="d-flex align-items-center justify-content-center w-50">
                                <h5 className="card-title my-0">Modifier Utilisateur</h5>
                            </div>
                        </div>
                        {this.state.error}
                        <form id="adminEditForm" onSubmit={this.onSubmit}>
                        <div className="form-group">
                                <label htmlFor="email">Adresse Email</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="email"
                                    name="email"
                                    placeholder="Adresse Email"
                                    value={this.state.email}
                                    onChange={this.handleInputChange} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="name">Nom</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="name"
                                    name="name"
                                    placeholder="Nom"
                                    value={this.state.name}
                                    onChange={this.handleInputChange} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="permissions">Permissions</label>
                                <select className="custom-select" name="permissions" value={this.state.permissions} onChange={this.handleInputChange}>
                                    <option value="normal">Normal</option>
                                    <option value="admin">Admin</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label htmlFor="password">Mot de Passe</label>
                                <input
                                    type="password"
                                    className="form-control"
                                    id="password"
                                    name="password"
                                    placeholder="Mot de Passe"
                                    value={this.state.password}
                                    onChange={this.handleInputChange} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="password2">Répéter le Mot de Passe</label>
                                <input
                                    type="password"
                                    className="form-control"
                                    id="password2"
                                    name="password2"
                                    placeholder="Répéter le Mot de Passe"
                                    value={this.state.password2}
                                    onChange={this.handleInputChange} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="authorized_reports">Rapports autorisés de:</label>
                                <Select
                                    id="authorized_reports"
                                    name="authorized_reports"
                                    isMulti
                                    placeholder="Séléctionner..."
                                    value={this.state.authorized_reports}
                                    options={this.state.possible_reports}
                                    closeMenuOnSelect={false}
                                    getOptionValue={option => JSON.stringify({id: option['id'], type: option['type']})}
                                    getOptionLabel={option => option['name']}
                                    onChange={this.handleMultiSelectChange} />
                            </div>
                            <button type="submit" className="btn btn-block btn-primary">Modifier</button>
                            <button type="button" className="btn btn-block btn-danger" onClick={() => this.deleteUser()}>Supprimer</button>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

class AdminNew extends Component {
    axiosCancelToken = Axios.CancelToken.source() // used to prevent state update on unmounted component after async request

    constructor(props) {
        super(props)
        this.state = {
            email: '',
            name: '',
            permissions: 'normal',
            password: '',
            password2: '',
            loading: false,
            error: null,
            possible_reports: [],
            authorized_reports: []
        }
    }

    handleInputChange = event => {
        const {value, name} = event.target
        this.setState({
            [name]: value
        })
    }

    handleMultiSelectChange = (newValue, actionMeta) => {
        this.setState({[actionMeta.name]: newValue})
    }

    onSubmit = event => {
        event.preventDefault()
        this.setState({loading: true})
        Axios.post(process.env.REACT_APP_API_HOST + '/api/users/', {
            name: this.state.name,
            email: this.state.email,
            password: this.state.password,
            password2: this.state.password2,
            permissions: this.state.permissions,
            authorized_reports: this.state.authorized_reports
        }, {withCredentials: true, cancelToken: this.axiosCancelToken.token})
            .then(res => {
                this.setState({loading: false})
                if (res.data.error) {
                    this.setState({error: <Message message={res.data.error_msg} type="warning" hidden={false} />})
                } else {
                    this.setState({error: <Message message="Création réussie" type="success" hidden={false} />})
                    this.props.history.push('/admin')
                }
            })
            .catch(err => this.props.axiosHandler(err, this))
    }
    
    componentDidMount() {
        Axios.get(process.env.REACT_APP_API_HOST + '/api/reporting/catalogs/possible_reports', {withCredentials: true, cancelToken: this.axiosCancelToken.token})
            .then(res => this.setState({possible_reports: res.data.possible_reports}))
            .catch(err => this.props.axiosHandler(err, this))
    }

    componentWillUnmount() {
        this.axiosCancelToken.cancel()
    }

    render() {
        return(
            <div className="container col-xl-5 col-lg-6 col-md-8 col-sm-10 col-xs-12">
                <div className="card">
                    {this.state.loading ? <Spinner /> : null}
                    <div className="card-body">
                        <div className="row d-flex align-items-center mb-3">
                            <div className="w-25 pl-2">
                                <button className="btn" onClick={this.props.history.goBack}><span className="fa fa-arrow-left" /></button>
                            </div>
                            <div className="d-flex align-items-center justify-content-center w-50">
                                <h5 className="card-title my-0">Nouvel Utilisateur</h5>
                            </div>
                        </div>
                        {this.state.error}
                        <form id="adminEditForm" onSubmit={this.onSubmit}>
                        <div className="form-group">
                                <label htmlFor="email">Adresse Email</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="email"
                                    name="email"
                                    placeholder="Adresse Email"
                                    value={this.state.email}
                                    onChange={this.handleInputChange} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="name">Nom</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="name"
                                    name="name"
                                    placeholder="Nom"
                                    value={this.state.name}
                                    onChange={this.handleInputChange} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="permissions">Permissions</label>
                                <select className="custom-select" name="permissions" value={this.state.permissions} onChange={this.handleInputChange}>
                                    <option value="normal">Normal</option>
                                    <option value="admin">Admin</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label htmlFor="password">Mot de Passe</label>
                                <input
                                    type="password"
                                    className="form-control"
                                    id="password"
                                    name="password"
                                    placeholder="Mot de Passe"
                                    value={this.state.password}
                                    onChange={this.handleInputChange} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="password2">Répéter le Mot de Passe</label>
                                <input
                                    type="password"
                                    className="form-control"
                                    id="password2"
                                    name="password2"
                                    placeholder="Répéter le Mot de Passe"
                                    value={this.state.password2}
                                    onChange={this.handleInputChange} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="authorized_reports">Rapports autorisés de:</label>
                                <Select
                                    id="authorized_reports"
                                    name="authorized_reports"
                                    isMulti
                                    placeholder="Séléctionner..."
                                    value={this.state.authorized_reports}
                                    options={this.state.possible_reports}
                                    closeMenuOnSelect={false}
                                    getOptionValue={option => JSON.stringify({id: option['id'], type: option['type']})}
                                    getOptionLabel={option => option['name']}
                                    onChange={this.handleMultiSelectChange} />
                            </div>
                            <button type="submit" className="btn btn-block btn-primary">Créer</button>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

class Admin extends Component {
    render() {
        return(
            <div>
                <Switch>
                    <PrivateRoute exact path='/admin' component={AdminIndex} componentProps={{history: this.props.history}} loggedIn={this.props.loggedIn} />
                    <PrivateRoute exact path={'/admin/edit/:id'} component={AdminEdit} loggedIn={this.props.loggedIn} />
                    <PrivateRoute exact path={'/admin/new'} component={AdminNew} loggedIn={this.props.loggedIn} />
                    <Route component={Error404} />
                </Switch>
            </div>
        )
    }
}

export default Admin
